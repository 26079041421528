import logo from './logo-w230.png'
import './App.css'

function App() {
  // rapelas.co.za

  return (
    <div className="App">
      <header className="App-header">
        <img className="logo" src={logo} alt="logo" />
        <p>Coming soon</p>
      </header>
    </div>
  )
}

export default App
